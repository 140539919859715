<template>
  <overlay-scrollbars class="page page-accounts" ref="os">
    <div class="box box_selectedAcc">
      <div class="header">
      </div>
      <div class="content">
        <div class="block" ref="bankOffers">
          <div class="hdr">
            <Bank2 class="img" /><div class="ttl">Bank account</div>
            <div @click="createBankAccount" class="btn create-account-btn">Create</div>
          </div>
          <Table
            class="bank-offer"
            :options="bankAcc" :key="bankAccKey"
            @editItem="editBankOffer"
            @deleteItem="deleteBankOffer"
            @change-page="getBankOffers"
            @scroll-top="toElTop"
            @sort="sortBy"
          />
        </div>
        <div class="block" ref="merchantOffers">
          <div class="hdr">
            <CreditCards class="img" /><div class="ttl">Merchant account</div>
            <div @click="createMerchantAccount" class="btn create-account-btn">Create</div>
          </div>
          <Table
            class="merchant-offer"
            :options="merchantAcc" :key="merchantAccKey"
            @editItem="editMerchantOffer"
            @deleteItem="deleteMerchantOffer"
            @change-page="getMerchantOffers"
            @scroll-top="toElTop"
            @sort="sortBy"
          />
        </div>
      </div>
    </div>
  </overlay-scrollbars>
</template>

<script>
import { mapMutations, mapState, mapActions } from 'vuex';

import Info from '../components/elem/Info.vue'
import Compliance from '../components/elem/Compliance.vue'
import Table from '../components/elem/Table.vue'
import Bank2 from '../components/img/Bank2.vue'
import CreditCards from '../components/img/CreditCards.vue'
import Pagination from 'laravel-vue-pagination'

export default {
  name: 'Accounts',
  components: {
    Info,
    Compliance,
    Table,
    Bank2,
    CreditCards,
    Pagination,
  },
  data: () => ({
    bankAccKey: 0,
    merchantAccKey: 0,
  }),
  computed: {
    ...mapState({
      bankAcc: state => state.accounts.bankAcc,
      merchantAcc: state => state.accounts.merchantAcc,
    }),
  },
  created () {
    this.$store.dispatch('setUser')
    this.getBankOffers()
    this.getMerchantOffers()
  },
  methods: {
    ...mapActions({
      getBankOffers: 'accounts/getBankOffers',
      getMerchantOffers: 'accounts/getMerchantOffers'
    }),
    createBankAccount () {
      this.$store.commit('setModal', {
        template: 'offer',
        options: {
          id: null,
          actionType: 'create',
          table: 'bank-offer',
          objectType: 'bank account',
          url: process.env.VUE_APP_BACKEND_API_URL + 'provider/offers',
          successfullMessage: 'You successfully created bank account',
        }
      })
    },
    createMerchantAccount () {
      this.$store.commit('setModal', {
        template: 'offer',
        options: {
          id: null,
          actionType: 'create',
          table: 'merchant-offer',
          objectType: 'merchant account',
          url: process.env.VUE_APP_BACKEND_API_URL + 'provider/offers',
          successfullMessage: 'You successfully created merchant account',
        }
      })
    },
    editBankOffer (obj) {
      this.$store.commit('setModal', {
        template: 'offer',
        options: {
          id: obj.id,
          actionType: 'edit',
          table: 'bank-offer',
          objectType: 'bank account',
          url: process.env.VUE_APP_BACKEND_API_URL + 'provider/offers/update/' + obj.id,
          successfullMessage: 'You successfully updated bank account',
        }
      })
    },
    deleteBankOffer (obj) {
      this.$store.commit('setModal', {
        template: 'delete',
        options: {
          id: obj.id,
          actionType: 'delete',
          table: 'bank-offer',
          type: 'offer',
          url: process.env.VUE_APP_BACKEND_API_URL + 'provider/offers/' + obj.id,
          successfullMessage: 'You successfully deleted offer',
        }
      })
    },
    editMerchantOffer (obj) {
      this.$store.commit('setModal', {
        template: 'offer',
        options: {
          id: obj.id,
          actionType: 'edit',
          table: 'merchant-offer',
          objectType: 'merchant account',
          url: process.env.VUE_APP_BACKEND_API_URL + 'provider/offers/update/' + obj.id,
          successfullMessage: 'You successfully updated merchant account',
        }
      })
    },
    deleteMerchantOffer (obj) {
      this.$store.commit('setModal', {
        template: 'delete',
        options: {
          id: obj.id,
          actionType: 'delete',
          table: 'merchant-offer',
          type: 'merchant',
          url: process.env.VUE_APP_BACKEND_API_URL + 'provider/offers/' + obj.id,
          successfullMessage: 'You successfully deleted offer',
        }
      })
    },
    updateCurrentPage(tableName, actionType) {
       if (tableName == 'bank-offer' || tableName == 'merchant-offer') {
           let currentPage = 1
           /*if (actionType == 'edit' || actionType == 'delete') {
             currentPage = document.getElementsByClassName(tableName)[0]
             .getElementsByClassName('pagination')[0]
             .getElementsByClassName('active')[0]
             .getElementsByClassName('page-link')[0]
             .innerText.trim().replace( /\D+/g, '')
           }*/
           if (tableName == 'bank-offer') {
             this.getBankOffers(currentPage)
           } else if (tableName == 'merchant-offer') {
             this.getMerchantOffers(currentPage)
           }
       }
    },
    sortBy (data) {
      this[data.obj].sort.loading = true
      this[data.obj].sort.order = this[data.obj].sort.name === data.sort && this[data.obj].sort.order === 'asc'
        ? 'desc' : 'asc'
      this[data.obj].sort.name = data.sort

      switch (data.obj) {
        case 'bankAcc':
          this.getBankOffers()
          break;
        case 'merchantAcc':
          this.getMerchantOffers()
          break;
      }
    },
    toElTop (el) {
      this.$refs.os.osInstance().scroll({ el: el, margin: 10 }, 1500)
    },
  },
  watch: { 
    '$store.state.deleteItemFromTableData': function(data) {
      this.updateCurrentPage(data.table, data.actionType)
    },
    '$store.state.editItemFromTableData': function(data) {
      this.updateCurrentPage(data.table, data.actionType)
    }
  }
}
</script>

<style lang="scss" scoped>
.page-accounts {
  .box {
    &_selectedAcc {
      .content {
        padding: 0 0 1rem;

        .block {
          margin-bottom: 1.5rem;

          .hdr {
            display: flex;
            align-items: center;
            margin-left: .75rem;
            margin-bottom: .5rem;

            svg.img {
              width: 1.75rem;
              height: 1.75rem;
              margin-right: .5rem;
            }

            .ttl {
              padding: .25rem .5rem;
            }
          }
        }
      }
    }
  }
}

.pagination {
    display: -ms-flexbox;
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: .25rem;
}

.page-link span {
    color: white;
}

.page-item.disabled .page-link {
    color: #6c757d;
    pointer-events: none;
    cursor: auto;
    background-color: #fff;
    border-color: #dee2e6;
}

.page-item:first-child .page-link {
    margin-left: 0;
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem;
}

.page-link:not(:disabled):not(.disabled) {
    cursor: pointer;
}

.page-link {
    position: relative;
    display: block;
    padding: .5rem .75rem;
    margin-left: -1px;
    line-height: 1.25;
    border: 1px solid #dee2e6;
    color: white !important;
}

.create-account-btn {
  border-radius: 1rem;
  padding: 10px;
  margin-left: 30px;
  background-color: var(--c_primary);
}
</style>